
import { defineComponent } from 'vue'
import { ulid } from 'ulid'

export default defineComponent({
  name: 'AppBody',
  props: {
    msg: String
  },
  data () {
    return {
      isLoading: false,
      newUlid: ulid()
    }
  },
  methods: {
    generateNewUlid () {
      this.isLoading = true
      setTimeout(() => {
        this.newUlid = ulid()
        this.isLoading = false
      }, 1000)
    }
  }
})
