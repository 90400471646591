<template>
  <header>
    <router-link to="/">
      <img alt="ULID" src="../assets/logo.png" />
    </router-link>
    <nav>
      <ul>
        <li><router-link to="/changelog">Changelog</router-link></li>
        <li><router-link to="/about">About</router-link></li>
      </ul>
    </nav>
  </header>
</template>

<style lang="scss">
header {
  display: flex !important;
  border-bottom: 1px solid #ccc;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;

  img {
    height: 24px;
  }

  p {
    margin-left: 1rem;
  }
}

nav {
  margin-left: auto;

  ul {
    list-style: none;
  }

  ul li {
    display: inline-flex;
    margin-left: 1rem;
  }

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
